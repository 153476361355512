@import '../../../colors.scss';

.manage_domains_popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    & > .add_domain {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 5px;
        width: 100%;
        margin: 10px 0;

        & > .field {
            width: 100%;
        }

        & > .action {
            position: absolute;
            right: 10px;
            top: 6px;
            color: $mainColor;
            font-size: 26px;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 35px;
            border-radius: 100%;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }

    & > .domain_list {
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        & > .head_domain {
            display: flex;
            justify-content: space-between;
            color: #585b69;
            font-size: 14px;
            padding: 0 5px;
        }
        
        & > .list {
            margin-top: 10px;

            & > .domain--selected {
                background: #d1dfef4d;
            }
            
            & > .domain {
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding: 10px 5px;
                border-bottom: 1px solid #eeeeee;

                &:last-child {
                    border-bottom: none;
                }

                & > .actions {
                    display: flex;
                    justify-content: flex-end;
                    
                    & > .action {
                        width: 33px;
                        height: 33px;
                        // background-color: #f8d5d1;
                        // color: #e15141;
                        padding: 7px;

                        svg {
                            width: 18px;
                        }
                    }

                    & > .action:first-child {
                        background-color: #d1dff0;
                        color: #417cc3;
                        margin-right: 10px;
                    }

                    & > .action:last-child {
                        background-color: #f8d5d1;
                        color: #e15141;
                    }

                    // & > .-edit {
                    //     background-color: #d1eaf8;
                    //     color: #41a6e1;
                    // }

                    // & > .-remove {
                    //     background-color: #f8d5d1;
                    //     color: #e15141;
                    // }
                }
            }
        }
    }

    & > .actions {
        display: flex;

        & > .action {
            color: white !important;
            margin: 0 5px !important;
            padding: 5px 20px !important;
            margin-top: 10px !important;
            background-color: $notActive;

            &:hover {
                background-color: $notActive;
                opacity: 0.9; 
                cursor: pointer;
            }
        }
    }
}

.manageDomains-dialog {
    .dialo_head_title {
        font-size: 20px;
    }
}

.domain .name {
    width: 180px;
    word-wrap: break-word;
}

.head_domain .label {
    width: 180px;
}

.head_domain .label:last-child {
    text-align: right;
}

.updateCancelButtons {
    position: absolute;
    right: 15px;

    button {
        box-shadow: none;
    }

    button:first-child {
        border: 1px solid #427dc3;
        color: #427dc3;
        margin-right: 5px;
    }

    button:last-child {
        background: #427dc3;
        color: white;
    }
}