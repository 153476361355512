@import './colors.scss';

body {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

.-column {
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.field > div > input {
  text-transform: uppercase !important;
}

.fields {
  &.-center {
    display: flex;
    justify-content: center;
  }
} 

.-regular_case {
  > div > input {
    text-transform: none !important;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.MuiFormHelperText-root {
  color: #80a0c9 !important;
}


.MuiInputLabel-animated {
  color: $text !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #d7e2ee !important;
}

.MuiInput-underline:after {
  border-bottom-color: $mainColor;
}

.MuiOutlinedInput-root {
  border-radius: 12px  !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #2B61A5 !important;
}

.MuiSelect-icon {
  color: #2B61A5 !important;
}

.MuiCheckbox-root {
  color: #80a0c9 !important;
}

.MuiOutlinedInput-input {
  padding: 13px 16px !important;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 15px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important; 
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important; 
} 

.potentialInfo .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d7e2ee !important;
}

.indirizzoDiSpedizione .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d7e2ee !important;
}

.search_input {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.customLabel {
  color: #2b61a5;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 4px;
}

// for tables

.MuiTableRow-head th {
  color: #585b69 !important;
}

.MuiTableRow-head th span {
  color: #585b69 !important;
}

.table_records {
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 24px !important;
  }
}

.table_scripts {
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 24px !important;
  }

  .MuiButton-root {
    padding: 0 !important;
  }
}

.table_templates {
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 24px !important;
  }

  .MuiButton-root {
    padding: 0 !important;
  }
}

.table_users {
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 24px !important;
  }

  .MuiButton-root {
    padding: 0 !important;
  }
}

.head_cell:first-of-type {
  padding-left: 24px !important;
}

.body_cell:first-of-type {
  padding-left: 24px !important;
}

.MuiTableCell-paddingNone:last-child {
  padding: 0 20px 0 0;
}

.MuiTableCell-root {
  border-bottom: 1px solid #eeeeee !important;
}

// dialogs

.dialog-title {
  padding: 32px 0 0 32px !important;
  h2 {
    font-size: 20px;
    font-weight: 700;
  }
}

.package-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d7e2ee !important;
  }

  .MuiDialogContent-root {
    padding: 8px 20px;
  }

  .MuiDialogActions-root {
    padding: 10px 32px 24px 32px;
  }
}

.createUser-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogContent-root {
    padding: 8px 20px;
  }
}

.preview-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogContent-root {
    padding: 8px 20px;
  }
}

.confirmDeletion-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogContent-root {
    padding: 8px 25px;
  }
}

.resetAuth-dialog  {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogContent-root {
    padding: 8px 25px;
  }
}

.manageDomains-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogTitle-root {
    padding: 26px 24px 5px 24px;
  }

}

.edit-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogTitle-root {
    padding: 26px 24px 5px 24px;
  }
}

.reports-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogTitle-root {
    padding: 26px 24px 5px 24px;
  }
}

.addAddresses-dialog {
  .MuiPaper-elevation24 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .MuiDialogActions-root {
    padding: 10px 26px 24px 26px;
  }

  .MuiDialogTitle-root {
    padding: 26px 24px 5px 24px;
  }
}

input[area-invalid=true] {

}

#modal_question {
  width: 412px;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: white;
  position: fixed;
  right: 50px;
  top: 30vh;

  & > .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}