@import '../../colors.scss';

.amin-login-fail {
    animation: login-fail 0.3s;
    position: relative;
}

.login_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    background-color: #3972b9;
    background-image: linear-gradient(to right bottom, #2e66aa, #336bb0, #376fb5, #3c74bb, #4079c1);

    .loginPage__text {
        font-size: 15px;
        color: #D7E2EF;
        text-align: center;
        margin-bottom: 13px;
    }

    .loginPage__text--accent {
        @extend .loginPage__text;
        color: #92EF90;
    }

    .loginPage__text--error {
        @extend .loginPage__text;
        text-align: center;
        color: rgb(255, 105, 105);
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.308);
        margin-top: 20px;
        animation: fadeIn 0.5s;
    }
}

.login_fields {
    width: 230px;
    margin-top: 10px;

    & label {
        color: white !important;
        opacity: 0.7;

        & + div {

            &:before {
                border-color: rgb(228, 228, 228) !important;
            }

            &:before:hover {
                border-color: grey !important;
            }

            &:after {
                border-color: white !important;
            }
        }
    }

    & input {
        color: #ffffffdb !important;
    }
}

.login_logo {
    width: 230px;
}

// verification 
.verificationSection {
    margin: 42px 0 32px 0;
    position: relative;
    animation: fadeIn 0.5s;


    &__backBtn {
        display: flex;
        align-items: center;
        color: #D7E2EF;
        font-size: 12px;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: 600;
        transition: .3s ease-in-out;
        cursor: pointer;
        padding: 5px 0;

        svg {
            margin-right: 5px;
        }

        &:hover {
            color: #fff;
        }
    }

    &__code {
        border: 1px solid #AFC2D8;
        border-radius: 8px;
        padding: 10px 12px;
        margin: 0 6px;
        outline: none;
        font-size: 24px;
        width: .9rem !important;
        color: #ffffff;
        background: transparent;
    }
    
    &__code--focus {
        border: 1px solid #ffffff;
    }

    &__code--error {
        border: 1px solid rgb(255, 77, 77);
    }

    &__response {
        margin-top: 35px;
        animation: riseHeight 0.5s;
        height: 70px;
    }

    &__successIconWrapper {
        margin:  0 auto;
        width: fit-content;
    }

    &__successIcon {
        width: 52px;
    }

    &__errorText {
        font-size: 12px;
        font-weight: 500;
        color: rgb(255, 58, 58);
        text-align: center;
        padding: 3px 10px;
        border-radius: 3px;
        width: fit-content;
        background: rgba(255, 255, 255, 0.885);
        position: absolute;
        top: 0;
        right: 0;
    }

    &__btn {
        width: fit-content;
        margin: 0 auto;
    }

    &__btn--disabled {
        @extend .verificationSection__btn;
        pointer-events: none;
        filter: grayscale(.4);
    }
}

.qrSection {    
    animation: fadeIn 0.5s;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.063);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 40px;
    width: 250px;

    &__accentText {
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        width: 200px;
        text-align: center;
    }

    &__qrWrapper {
        margin: 0 auto;
        margin-top: 30px;
        width: fit-content;
        border-radius: 10px;
        background: #ffffff;
        height: 130px;
        width: 130px;
            
            img {
                width: 100%;
                border-radius: 10px;
                background: #ffffff;
            }
    }

    &__loading {
        margin-top: 30px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        padding: 50px 0;
    }

}

.verificationSuccess {
    margin-top:  40px;

    &__text {
        font-size: 24px;
        color: #ffffff;
        text-align: center;
    }

    &__successIconWrapper {
        margin:  0 auto;
        margin-top: 20px;
        width: fit-content;
    }

    &__successIcon {
        width: 52px;
    }
}

.googleVerification {
    margin-top: 42px;
    &__verifyBtn {
        margin:  0 auto;
        margin-top: 32px;
        width: fit-content;
    }
}

@keyframes login-fail {
    25% {
        left: 20px;
    } 

    50% {
        left: 0px;
        right: 20px;
    }

    75% {
        right: 0px;
        left: 20px;
    }

    to {
        left: 0px;
        right: 20px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes riseHeight {
    0% {
        height: 0;
        opacity: 0;
    }

    100% {
        height: 70px;
        opacity: 1;
    }
}


.menu__googleBtn {
    border-radius: 8px;
    background: #fff;
    margin-top: 20px;       
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    transition: .2s ease-in-out;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    img {
       margin-right: 10px;
       width: 20px;
    }
}